@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;


@tailwindcss forms;

body {
  @apply leading-[normal] m-0;
  background-color: white;
}
*,
::before,
::after {
  border-width: 0;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #303030;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  white-space:nowrap;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 130%;
  left: 50%;
  transform: translateX(-50%) translateY(10px); 
  padding: 5px 7px;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease; 
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.15);
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent #303030 transparent;
  
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  transform: translateX(-50%) translateY(0);
  opacity: 1; /* Fade in */
}